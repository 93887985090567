import React from "react";

class Range extends React.Component {
    constructor(props) {
        super(props);

        this.isDragging = false;
        this.currentTranslate = 0;

        this.swipeStart = this.swipeStart.bind(this);
        this.swipeMove = this.swipeMove.bind(this);
        this.swipeEnd = this.swipeEnd.bind(this);
    }

    render() {
        return(<div className="range-input" ref={(el) => this.range = el}>
            <div className="range__band"></div>
            <div className="range__btn" ref={(el) => this.btn = el}>
                <div className="range__btn-handle"></div>
            </div>
        </div>);
    }

    componentDidMount() {
        console.log("componentDidMount");

        const block = document.querySelector(".player__panel");
        // Нажатие
        block.addEventListener( "mousedown", this.swipeStart );
        block.addEventListener( "touchstart", this.swipeStart );

        block.addEventListener( "mousemove", this.swipeMove );
        block.addEventListener( "touchmove", this.swipeMove );

        block.addEventListener( "mouseup", this.swipeEnd );
        block.addEventListener( "mouseleave", this.swipeEnd );
        block.addEventListener( "touchmove", this.swipeEnd );
    }

    swipeStart() {
        this.isDragging = true;
    }

    swipeMove(event) {
        if (!this.isDragging || this.range === null) return;
        const rangePos = this.range.getBoundingClientRect();
        const btn_x = event.pageX - rangePos.x - 9,
            width = this.range.offsetWidth;
        if (btn_x < 0 || btn_x + 19 > width) {
            return;
        }
        this.btn.style.left = btn_x  + "px";
        this.props.changeVolume(btn_x / width);
    }

    swipeEnd() {
        this.isDragging = false;
    }
}

export default Range;