import React from "react";
import axios from 'axios';

import Slab from "./components/Slab";
import Playback from "./components/Playback";

// import logo from './logo.svg';

const SlideActive = "slabs__active";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            play: false,
            playBlockIndex: -1,
            playNextBlockIndex: -1,
            data: undefined
        };
        
        this.onClickPlay = this.onClickPlay.bind(this);
        this._request = this._request.bind(this);

        this.nowplaying = [
            "bussines__kammerton", "run_kammertone", "walk_kammertone"
        ];
        this.audio = null;
        this.volume = 1;
        this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    render() {
        return (<>
            <header className="header">
                <div className="header__container">
                    <img className="header__logo" src="img/Kammertone.svg" alt="Kammertone" />
                    <h1 className="header__descript">Разная музыка - разные задачи</h1>
                </div>
            </header>
            <main className={ this.isMobile ? "main mobile" : "main" }>
                <div className="main__container">
                    <div className="slabs" ref={(el) => {this.slabs = el}}>
                        <Slab image={[0, "/img/slab_1.jpg"]} name="Бизнес" data={this.state.data} isMobile={this.isMobile} onClickPlay={this.onClickPlay} />
                        <Slab image={[1, "/img/slab_2.jpg"]} name="БЕГ" data={this.state.data} isMobile={this.isMobile} onClickPlay={this.onClickPlay} />
                        <Slab image={[2, "/img/slab_3.jpg"]} name="Прогулка" data={this.state.data} isMobile={this.isMobile} onClickPlay={this.onClickPlay} />
                    </div>
                </div>
            </main>
            { !this.isMobile && <div className="playback" ref={(el) => {this.playback = el}}>
                <Playback data={this.state.data} statePlay={this.state} onClickPlay={this.onClickPlay} changeVolume={this.changeVolume.bind(this)} />
            </div>}
            <div id="audioPlay"></div>
        </>);
    }

    componentDidMount() {
        setInterval(() => {
            const index = this.state.playBlockIndex === -1? this.state.playNextBlockIndex : this.state.playBlockIndex;
            this._request(index, 
            (response) => {
                if (response && response.data.now_playing.song.id !== this.state.data?.now_playing.song.id) {
                    this.changeMusic(response);
                }
                if (!this.listeners || response.data.station.shortcode !== this.nowplaying[index]) return; 
                this.listeners.innerHTML = response.data.listeners.unique;

                console.log("listeners", response.data.listeners.unique);
            }, (err) => {
                this._messageError(err, "Ошибка, не удалось получить данные");
            });
        }, 10000);

        navigator.mediaSession.setActionHandler("play", async () => {
            console.log("mediaSession play");

            if (this.audio === null) return;

            await this.audio.play();
            navigator.mediaSession.playbackState = "playing";

            this.onClickPlay(this.state.playBlockInde);
        });
        navigator.mediaSession.setActionHandler("pause", async () => {
            console.log("mediaSession pause");

            if (this.audio === null) return;

            await this.audio.pause();
            navigator.mediaSession.playbackState = "paused";
            
            this.onClickPlay(this.state.playBlockIndex);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("componentDidUpdate", prevState.play, this.state.play, this.state.playBlockIndex);

        if (this.state.playBlockIndex === prevState.playBlockIndex || !this.state.play) return;

        this._request(this.state.playBlockIndex, (response) => {
            console.log("Respons livelyoneapp");

            this.setState({ data: response.data });

            this.audio = new Audio(response.data.station.listen_url);
            this.audio.volume = this.volume;

            this.audio.play()
                .then((() => {
                    this.changeMusic(response);
                    console.log("Play sound");

                    if (this.isMobile) {
                        this.listeners = document.querySelector(".slabs__active .slabs-item__player-listeners p");
                        return;
                    }
                    else {
                        this.playback.classList.add("playback__show");
                    }
                    if (!this.listeners) {
                        this.listeners = document.querySelector(".player__listeners p");
                    }
                })
                ).catch((err) => {
                    document.title = "Kammertone";
                    this._messageError(err, "Произошла ошибка воиспроизведения");
                });
        }, (err) => {
            this._messageError(err, "Ошибка, сервер станции не отвечает");
        });
    }

    onClickPlay(index) {
        const stopPlay = () => {
            if (this.audio === null) return;

            this.audio.pause();
            this.audio.src = "";
            this.audio = null;
        }, slab = this.slabs.children[index];

        if (index > this.slabs.children.length - 1) return;

        if (this.state.play && this.state.playBlockIndex === index) {
            stopPlay();
            slab.classList.remove(SlideActive);
            slab.querySelector(".slabs-item__play-icon").style.backgroundImage = "url(img/play_white.svg)";

            this.setState({ playBlockIndex: -1, play: false });
            return;
        } 
        else if (this.state.play && this.state.playBlockIndex !== index) {
            stopPlay();
            this.slabs.children[this.state.playBlockIndex].classList.remove(SlideActive);
            this.slabs.children[this.state.playBlockIndex].querySelector(".slabs-item__play-icon").style.backgroundImage = "url(img/play_white.svg)";
        }

        slab.classList.add(SlideActive);
        slab.querySelector(".slabs-item__play-icon").style.backgroundImage = "url(img/pause_white.svg)";

        this.setState({
            playBlockIndex: index,
            playNextBlockIndex: index,
            play: true 
        });
    }

    changeMusic(response) {
        this.setState({ data: response.data });
        if ("mediaSession" in navigator) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title: response.data.now_playing.song.title,
                artist: response.data.now_playing.song.artist,
                album: response.data.now_playing.song.album,
                artwork: [{ src: response.data.now_playing.song.art, type: 'image/png' }]
            });
        }
        document.title = `${response.data.now_playing.song.title} - ${response.data.now_playing.song.artist}`;
    }

    changeVolume(volume) {
        if (isNaN(volume)) return;

        this.volume = volume;
        if (this.audio ? true : false) this.audio.volume = volume;
        if (this.isMobile) return;

        let img = document.querySelector(".player__panel img");

        if (volume < 0.005) img.src = "img/volume-slash.svg";
        else if (volume < 0.51) img.src = "img/volume-low.svg";
        else img.src = "img/volume-high.svg";
    }
    
    _request(index, func, func_err) {
        if (index === -1 ) return; 
        axios.get("https://server.livelyoneapp.ru/api/nowplaying/" + this.nowplaying[index])
            .then(func)
            .catch(func_err);
    }

    _messageError(err, message) {
        console.log("Error:", err);
        alert(message);
    }
}

export default App;
