import React from "react";
import Range from "./Range";

class Playback extends React.Component {
    render() {
        console.log("Playback");
        if (this.props.data === undefined) {
            return (<div className="playback__body">
                <div className="playback__player">
                    <div className="player__title">Загрузка данных</div>
                </div>
            </div>);
        }

        const playing = this.props.data.now_playing;

        return (<div className={ "playback__body " + (this.props.statePlay.play? "": "playback__stop") } ref={(el) => {this.playback = el;}}>
            <div className="playback__info">
                <div className="playback__info-body">
                    <div className="playback__info-title">{this.props.statePlay.play? "Играет": "Пауза"}</div>
                </div>
            </div>
            <div className="playback__player">
                <div className="player__img" style={{ backgroundImage: `url(${playing.song.art})` }} loading="lazy">
                    <div className="player__img-column"></div>
                    <div className="player__img-column"></div>
                    <div className="player__img-column"></div>
                </div>
                <div className="player__song">
                    <div className="player__title">{playing.song.title}</div>
                    <div className="player__descript">{playing.song.artist}</div>
                </div>
                <div className="player__listeners">
                    <p>{this.props.data.listeners.unique}</p>
                    <img src="img/user_logo.svg" alt="слушатели" />
                </div>
                <div className="player__panel">
                    <img src="img/volume-high.svg" alt="звук" />
                    <Range changeVolume={this.props.changeVolume} />
                </div>
                <div className="player-button">
                    <img src={this.props.statePlay.play? "img/puse_black.svg": "img/play.svg"} alt="запуск" onClick={
                        (e) => {
                            console.log("player-button", e, this);
                            this.props.onClickPlay(
                                this.props.statePlay.playBlockIndex === -1 ? this.props.statePlay.playNextBlockIndex : this.props.statePlay.playBlockIndex
                            );
                        }
                    } />
                </div>
            </div>
        </div>);
    }
}

export default Playback;