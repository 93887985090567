import React from "react";

class Slab extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (<div className="slabs-item__body" ref={(el) => {this.slab = el;}}>
            <div className="slabs-item__img" style={{ backgroundImage: `url(${this.props.image[1]})` }}></div>
            <div className="slabs-item__title">{this.props.name}</div>
            <div className="slabs-item__play">
                <div className="slabs-item__play-icon" style={{ backgroundImage: 'url(img/play_white.svg)' }} onClick={
                    (e) => this.props.onClickPlay(this.props.image[0] )
                }></div>
            </div>
            { this.props.isMobile && this.props.data && <div className="slabs-item__playinfo">
                <div className="player__img" style={{ backgroundImage: `url(${this.props.data.now_playing.song.art})` }} loading="lazy">
                    <div className="player__img-column"></div>
                    <div className="player__img-column"></div>
                    <div className="player__img-column"></div>
                </div>
                <div className="player__song">
                    <div className="player__title">{this.props.data.now_playing.song.title}</div>
                    <div className="player__descript">{this.props.data.now_playing.song.artist}</div>
                </div>
                <div className="slabs-item__player-listeners">
                    <p>{ this.props.data.listeners.unique }</p>
                    <img src="img/user-white.svg" alt="слушатели" />
                </div>
            </div> }
        </div>);
    }
}

export default Slab;